/* styles.module.css */

/* Style the chatbot container */
.react-chatbot-kit-chat-container {
  position: fixed;
  top: 50%; /* Center the chatbot vertically */
  left: 50%; /* Center the chatbot horizontally */
  transform: translate(-50%, -50%); /* Adjust the chatbot to be centered */
  z-index: 9998; /* Ensure it's on top of other elements */
  width: 600px !important; /* Set desired width */
  height: 700px !important; /* Set desired height */
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px !important;
  overflow: hidden !important;
}

/* Style the chatbot inner container to fill the outer container */
.react-chatbot-kit-chat-inner-container {
  width: 600px !important;
  height: 700px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Make sure input stays at the bottom */
}

/* Style the message container */
.react-chatbot-kit-chat-message-container {
  width: 100% !important;
  height: 100% !important;
  flex-grow: 1;
  padding: 18px 22px 40px 22px;
  overflow-y: auto; /* Enable scrolling if messages overflow */
  background: none;
}

.react-chatbot-kit-chat-widget-suggestions {
  padding-bottom: 1rem;
}

.react-chatbot-kit-chat-header {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 50.72%,
      rgba(255, 255, 255, 0.4) 100%
    ),
    linear-gradient(90deg, #5dc6cc 0%, #7279ee 98%);
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  font-size: 22px;
}

.react-chatbot-kit-chat-header-close-button {
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.react-chatbot-kit-chat-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.react-chatbot-kit-chat-widget-suggestions-header {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  color: white;
}

.react-chatbot-kit-chat-widget-suggestions-container {
  display: flex;
  justify-content: center;
  gap: 17px;
}

.react-chatbot-kit-chat-widget-suggestions-item {
  padding: 24px 16px;
  color: white;
  background: black;
  border: none;
  width: 159px;
  border-radius: 8px;
  text-align: left;
}

.react-chatbot-kit-chat-widget-suggestions-item:hover {
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
  color: #ffffff;
}

.react-chatbot-kit-chat-bot-message {
  background: none;
  width: 100%;
  padding: 0;
}

.react-chatbot-kit-chat-bot-avatar-container {
  margin-top: 1.25rem;
}

.react-chatbot-kit-chat-inner-container {
  background: #242424;
  color: white;
}

.react-chatbot-kit-chat-input-form {
  background: #424242;
  border-radius: 999px;
  padding: 0.5rem 0.6rem 0.5rem 1rem;
  margin: 18px 22px;
}

.react-chatbot-kit-chat-input {
  background: none;
  color: white;
  padding: 0.5rem;
  border: none;
  width: 100%;
}

.react-chatbot-kit-chat-btn-send {
  color: white;
  background: #5d5d5d;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  flex-shrink: 0;
  content: "";
  margin-left: 1rem;
}

.react-chatbot-kit-chat-btn-send::after {
  content: url("/images/arrow-up.svg");
}

.react-chatbot-kit-chat-btn-send-icon {
  display: none;
}
