:root {
  --fc-non-business-color: rgba(215, 215, 215, 0.6);
}

.fc-timegrid-slots tr {
  height: 3em;
}

.fc-event {
  width: 90% !important;
}

.CallHistoryText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
