/* styles.module.css */

/* Style the chat button */
.chatToggleButton {
  position: fixed;
  bottom: 20px; /* Button stays at the bottom */
  right: 20px;
  z-index: 9999; /* Button remains visible */
  background-color: #2898ec;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.chatToggleButton:hover {
  background-color: #1d7ccc;
}

.botMessageContainer {
  background-color: #e0f7fa; /* Light teal background for bot messages */
  color: #004d40; /* Dark teal text color */
  padding: 10px 15px; /* Padding inside the message bubble */
  border-radius: 12px; /* Rounded corners for the message bubble */
  max-width: 80%; /* Limit the width of the message bubble */
  word-wrap: break-word; /* Ensure long words wrap inside the bubble */
  margin: 10px 0; /* Margin between messages */
  align-self: flex-start; /* Align to the left (as it's a bot message) */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  font-size: 14px; /* Slightly smaller text size for the message */
  line-height: 1.5; /* Space between lines of text */
}

.botMessageContainer p {
  margin: 0; /* Remove default margins from paragraphs */
}

.botMessageContainer ul {
  margin: 0;
  padding-left: 20px; /* Indent lists inside the message bubble */
}

.botMessageContainer li {
  margin-bottom: 5px; /* Space between list items */
}
